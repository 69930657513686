import { ActionContext, fireEvent } from '../cra';
import { Button, Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import { FiPhone } from 'react-icons/fi';
import Layout from '../component/layout/layout';
import LeadBrandUtils from '../utils/leadBrandUtils';
import SEO from '../component/seo';
import YoutubeVideo from '../component/video/YoutubeVideo';
import { getContact } from '../utils/getContact';

const isBrowser = typeof window !== 'undefined';

const ThanksPage = () => {
  const { trackCurrentPage } = React.useContext(ActionContext);
  React.useEffect(() => {
    trackCurrentPage('Thanks Page');
  }, []);
  const [notIE, setNotIE] = useState(false);

  const [leadBrand, setLeadBrand] = useState('');
  const [loading, setLoading] = useState(true);

  if (typeof window !== 'undefined' && notIE) {
    window.g3cm =
      window.g3cm ||
      function () {
        // eslint-disable-next-line no-extra-semi
        (window.g3cm.q = window.g3cm.q || []).push(arguments);
      };
    window.g3cm('loadjquery', 'true');
    const phone = document.cookie.match(/leadphone=(\d+);?/);
    if (phone) {
      window.g3cm('phonenumber', phone[1]);
      window.g3cm('send', 'init');
    }
  }

  useEffect(() => {
    if (isBrowser) {
      window.onbeforeunload = null;

      if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
        return;
      } else {
        setNotIE(true);
      }
      const queryParams = new URLSearchParams(window.location.search);
      const brand =
        queryParams &&
        queryParams.has('leadBrand') &&
        queryParams.get('leadBrand');
      setLeadBrand(brand);
      setLoading(false);
    }
    window.Invoca && window.Invoca.PNAPI.run();
  }, []);

  return (
    !loading && (
      <>
        <SEO title='Thanks' />

        <Layout stickyFooter={false}>
          {notIE && (
            <script
              src='https://api.connectstreams.com/js/connectme-v3.min.js'
              async='async'
            ></script>
          )}
          <Row
            className='g-0 sidebar-layout thanks-layout'
            style={{ backgroundColor: '#E5E5E5' }}
          >
            <Col
              xs={12}
              sm={12}
              md={4}
              lg={3}
              className={`d-none d-md-block ${
                LeadBrandUtils.isNonMedicare()
                  ? 'thanks-left-sec-life-compared'
                  : 'thanks-left-sec'
              }`}
            >
              <div className='sidebar-section'></div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={9} className='thanks-section'>
              <div className='thanks-content'>
                <h3>
                  Wow! We found over <span>250 plans</span> in your area
                </h3>
                <div
                  className='thanks-subtitle g3cm_holder'
                  data-module='1493'
                  data-showoninit='true'
                  style={{ display: 'none' }}
                >
                  <p>
                    Our Medicare Experts are working hard to find the best plans
                    for you. Thank You for your inquiry to Medicare Compared!
                  </p>
                </div>
                <div className='thanks-subtitle'>
                  {LeadBrandUtils.isNonMedicare() ? (
                    <>
                      <p>
                        Thank you for trusting Life Compared to give you the
                        most accurate life insurance rates in your area. We
                        appreciate your time and we&apos;d love to get you your
                        plan quotes right away.
                      </p>
                      <p>Give us a call to review your quotes!</p>
                    </>
                  ) : (
                    <p>
                      Give us a call so we can email or mail the plans to you!
                    </p>
                  )}
                  <a
                    className='phone-number-fade'
                    href={getContact(leadBrand).href}
                    rel='noopener noreferrer'
                    id='callInNum'
                    onClick={(e) => {
                      fireEvent('initiateCall', e, {
                        description: 'Request Callback',
                        value: getContact(leadBrand).text,
                      });
                    }}
                  >
                    <Button variant='btn btn-primary-custom xs-mb10'>
                      <FiPhone color='white' style={{ marginRight: 20 }} />
                      Call <span>{getContact(leadBrand).text}</span>
                    </Button>
                  </a>
                </div>
                <hr style={{ width: '100%' }} />

                {leadBrand !== 'life compared' && (
                  <div>
                    <div className='thanks-video-text'>
                      <p>
                        If you have an extra couple of minutes, please watch the
                        video
                      </p>
                      <div className='thanks-video'>
                        <YoutubeVideo />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Layout>
      </>
    )
  );
};
export default ThanksPage;
