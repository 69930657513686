import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';

import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import React from 'react';

class YoutubeVideo extends React.Component {
  render() {
    return (
      <>
        <div className='video-wrap'>
          <div className='iframe'>
            <LiteYouTubeEmbed
              id='naUMczeGW9Q'
              title='Play: Medicare Compared: We CARE about your medicare'
            ></LiteYouTubeEmbed>
          </div>
        </div>
      </>
    );
  }
}

export default YoutubeVideo;
